import { AlertOption } from "../amr-pipeline/enums/AlertOption";
import { CollateralType } from "../amr-pipeline/enums/CollateralType";
import { Equity } from "../amr-pipeline/enums/Equity";
import { FloaterIndex } from "../amr-pipeline/enums/FloaterIndex";
import { TransactionStatus } from "../amr-pipeline/enums/TransactionStatus";
import { TransactionType } from "../amr-pipeline/enums/TransactionType";
import { Currency } from "../enums/Currency";
import { Rating } from "../enums/Rating";
import { TimeOption } from '../filters/TimeOption';

export enum DateRangeOption {
    NextOneAndHalf = 'NextOneAndHalf',
    NextThreeAndHalf = 'NextThreeAndHalf',
    FromOneAndHalfToThreeAndHalf = 'FromOneAndHalfToThreeAndHalf',
    FromThreeAndHalfToFiveAndHalf = 'FromThreeAndHalfToFiveAndHalf',
    YearsRange = 'YearsRange',
    ThisWeek = 'ThisWeek',
    LastWeek = 'LastWeek',
    ThisMonth = 'ThisMonth',
    LastMonth = 'LastMonth',
    LastThreeMonth = 'LastThreeMonth',
    LastSixMonth = 'LastSixMonth',
    Custom = 'Custom',
    Today = 'Today',
    ThisYear = 'ThisYear',
    LastYear = 'LastYear',
    CurrentInventory = 'CurrentInventory',
    All = 'All',
    TodayAndUpcoming = 'TodayAndUpcoming',
    ThisWeekMondayToFriday = 'ThisWeekMondayToFriday',
    Yesterday = 'Yesterday',
}

export enum DateTimeRangeOption {
    TodayWithTime = 'Today',
    DateWithTime = 'DateWithTime',
    DateWithTimePeriod = 'DateWithTimePeriod',
}

interface DateWithTime {
    value: string;
    option: TimeOption;
}

export interface UserConfigRangeFilter<T> {
    from?: T;
    to?: T;
}

export interface UserConfigDateFilter {
    value: UserConfigRangeFilter<string>,
    name: DateRangeOption,
}

export interface UserConfigDateTimeFilter {
    value: UserConfigRangeFilter<DateWithTime>
    name: DateTimeRangeOption,
}

export interface UserFilterValues {
    date?: UserConfigDateFilter;
    size?: UserConfigRangeFilter<number>;
    types?: TransactionType[];
    statuses?: (TransactionStatus | string)[];
    ratings?: Rating[];
    currency?: Currency[];
    dealerIds?: number[];
    outOfNC?: boolean;
    outOfRI?: boolean;
    dealName?: string[];
    classNames?: string[];
    trustees?: string[];
    collateralManagers?: string[];
    arrangers?: string[];
    currencyCodes?: Currency[];
    collateralTypes?: CollateralType[];
    pricingDate?: UserConfigDateFilter;
    closingDate?: UserConfigDateFilter;
    maturity?: UserConfigDateFilter;
    nonCallPeriodEnd?: UserConfigDateFilter;
    rollerDeadline?: UserConfigDateTimeFilter;
    reinvestmentPeriodEnd?: UserConfigDateFilter;
    nonCallPeriodEndDays?: UserConfigRangeFilter<number>;
    reinvestmentPeriodEndDays?: UserConfigRangeFilter<number>;
    was?: UserConfigRangeFilter<number>;
    warf?: UserConfigRangeFilter<number>;
    ds?: UserConfigRangeFilter<number>;
    parSubordination?: UserConfigRangeFilter<number>;
    mvoc?: UserConfigRangeFilter<number>;
    amrDeal?: boolean;
    euCompliance?: boolean;
    esg?: boolean;
    sofr?: boolean;
    isDebut?: boolean;
    enhancedCLO?: boolean;
    staticDeal?: boolean;
    equity?: Equity[];
    anchor?: boolean;
    riEnd?: UserConfigRangeFilter<number>;
    ncEnd?: UserConfigRangeFilter<number>;
    vintage?: UserConfigDateFilter;
    coupons?: FloaterIndex[];
}

export interface UserConfigFilter {
    referenceName: string;
    name: string;
    default: boolean;
    filter: UserFilterValues;
    alertOption: AlertOption;
}

export interface PortfolioUserConfigFilter extends UserConfigFilter {
    bwicAlertOption: AlertOption;
    dealerInventoryAlertOption: AlertOption;
}

export interface UserFilterAlertStatus {
    referenceName: string;
    alertOption: AlertOption;
}
