export enum AlertOption {
    Never = 'Never',
    Daily = 'Daily',
    Instant = 'Instant',
}

export enum StatisticsAlertOption {
    Never = 'Never',
    Weekly = 'Weekly',
}
